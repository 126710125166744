import React, { Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import './styles.css';

import Header from "../../Dashboard/Header";
import QuotationsTable from "../../Table/Quotations";

import { QUOTATIONS_MODEL } from '../../Table/utils/models';
import FilesSection from "./FilesSection";
import { roleDeptValidation } from "../../../utils/privilegeValidation";
import { formatDateTimeForUserTimeZone } from "../../../utils/format";

function OrderDetail(props: any) {

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { user } = props;
  const { order } = props;
  const { quotations } = props;

  const { order_custom_id, order_id, client_custom_id, client_id, status, client_email, creation_date, client_company_name,
    payment_type, payment_reference, cut_type, material_type, material_count, cut_count, thickness, thickness_unit, width, length, width_length_unit, notes, } = order;

  const payed_quotation = quotations.find((item: any) => item.quotation_id === props.order.payed_quotation_id);

  const { reception_image_urls, delivery_image_urls } = props

  const { handleUploadImage, handleTransferPayment, handleDownload, handleChangeStatus, handleDelete, handleObservations } = props;

  const formated_creation_date = formatDateTimeForUserTimeZone(creation_date);



  function renderQuotationTable() {
    if (quotations.length !== 0 && roleDeptValidation(['SuperUser', 'Admin', 'Operator'], ['Admin', 'Administracion', 'Ventas'], user)) {
      return <QuotationsTable data={quotations} columns={QUOTATIONS_MODEL} />
    }
    return null
  }

  function renderActions() {
    if (roleDeptValidation(['SuperUser', 'Admin', 'Operator'], ['Admin', 'Administracion', 'Ventas'], user)) {
      switch (status) {
        case 'pending_quotation':
          return <Link
            to={`/dashboard/orders/${order_id}/${client_id}/quotation/create`}
            className="btn ripple btn-primary  mb-1 me-2"
          >
            <i className="fe fe-credit-card me-1"></i> Crear cotizaciones
          </Link>
        case 'awaiting_payment':
          return <button
            type="button"
            className="btn ripple btn-info mb-1 me-2"
            onClick={function () { handleTransferPayment() }}
          >
            <i className="far fa-credit-card"></i> Pago por transferencia
          </button>

        case 'payed':
          return <button
            type="button"
            className="btn ripple btn-info mb-1 me-2"
            onClick={function () { handleChangeStatus("in_progress") }}
          >
            <i className="fa fa-spinner"></i> Orden en progreso
          </button>

        case 'in_progress':
          return <button
            type="button"
            className="btn ripple btn-info mb-1 me-2"
            onClick={function () { handleChangeStatus("done") }}
          >
            <i className="fa fa-check"></i> Orden terminada
          </button>
        case 'done':
          return <button
            type="button"
            className="btn ripple btn-info mb-1 me-2"
            onClick={function () { handleChangeStatus("delivered") }}
          >
            <i className="fa fa-truck"></i> Orden entregada
          </button>
        default:
          return null
      }
    }
    return null

  }

  function renderCancelAction() {
    if (['delivered', 'done'].includes(status)) {
      return null
    }

    if (roleDeptValidation(['SuperUser', 'Admin', 'Operators'], ['Admin', 'Administracion', 'Ventas'], user)) {
      return <button
        type="button"
        className="btn ripple btn-danger mb-1 me-2"
        onClick={function () { handleChangeStatus("canceled") }}
      >
        <i className="fa fa-ban"></i> Cancelar
      </button>
    }

    return null

  }

  return (
    <Fragment>
      <Header title="DETALLE DE PEDIDO" />
      <div className="row row-sm">
        <div className="col col-md-12 col-lg-12">
          <div className="card custom-card">
            <>
              <div ref={componentRef} className="card-body">
                <div className="d-lg-flex">
                  <h6 className="main-content-label mb-4">
                    <span className="d-flex mb-4">
                      <Link to={'/dashboard/orders'}>
                        <img
                          src={require("../../../assets/img/brand/cortenLogo.png")}
                          className="sign-favicon ht-50"
                          alt="logo"
                        />
                      </Link>
                    </span>
                  </h6>
                  <div className="ms-auto">
                    <div className="mb-1">
                      <h5 className="font-weight-bold">ID Cliente: {client_custom_id || client_id}</h5>
                      <div className="row d-flex justify-content-between">
                        <div className="col d-flex justify-content-center align-items-center">
                          <p className="h1 mb-4 mt-4 text-end">Detalles de Orden </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-sm pt-2">
                  <div className="col col-lg-3 text-start">

                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Cliente:</span>
                      </p>
                      <address className="fontOrange text-end">{client_company_name}</address>
                    </div>

                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Email:</span>
                      </p>
                      <address className="fontOrange text-end">{client_email}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Pedido:</span>
                      </p>
                      <address className="fontOrange text-end">{order_custom_id || order_id}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Status:</span>
                      </p>
                      <address className="fontOrange text-end">{status}</address>
                    </div>

                    {payment_type !== 'undefined' ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de Pago:</span>
                      </p>
                      <address className="fontOrange text-end">{payment_type}</address>
                    </div> : null}

                    {payment_reference ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Referencia de Pago:</span>
                      </p>
                      <address className="fontOrange text-end">{payment_reference}</address>
                    </div> : null}

                    <br />
                  </div>

                  <div className="col col-lg-3 text-start">
                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de corte:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Espesor:</span>
                      </p>
                      <address className="fontOrange text-end">{thickness} {thickness_unit}</address>
                    </div>
                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Largo x Ancho</span>
                      </p>
                      <address className="fontOrange text-end">{length} x {width} {width_length_unit}</address>
                    </div>

                    {payed_quotation?.machine_scheduled_date ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha a maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(payed_quotation.machine_scheduled_date)}</address>
                    </div> : null}

                    {payed_quotation?.machine_duration_minutes ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tiempo en maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{payed_quotation.machine_duration_minutes} Minutos</address>
                    </div> : null}

                    <br />
                  </div>

                  <div className="col col-lg-3 text-start">

                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas a cortar:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_count} Piezas</address>
                    </div>

                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_count} Piezas</address>
                    </div>
                   
                    
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Notas:</span>
                      </p>
                      <address className="fontOrange text-end">{notes}</address>
                    </div>
                   

                  </div>

                  <div className="col col-lg-3 text-start">
                  <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha de creacion:</span>
                      </p>
                      <address className="fontOrange text-end">{formated_creation_date}</address>
                    </div>
                    {payed_quotation?.material_reception_date ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha recepción material:</span>
                      </p>
                      <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(payed_quotation.material_reception_date)}</address>
                    </div> : null}

                    {payed_quotation?.due_date ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha entrega pedido:</span>
                      </p>
                      <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(payed_quotation.due_date)}</address>
                    </div> : null}
                    {
                      payed_quotation?.extras?.map( (extra: any) => (
                      <>
                      <div>
                        <p className="mb-1 text-end">
                          <span className="fs-4">{extra.description === 'extra_material_reception' ? "Extra de Recoleccion": "Extra de entrega"}</span>
                        </p>
                        <address className="fontOrange text-end">{extra?.price} MXN</address>
                      </div>
                      <div>
                        <p className="mb-1 text-end">
                          <span className="fs-4">Direccion:</span>
                        </p>
                        <address className="fontOrange text-end">{extra?.user_input}</address>
                      </div> 
                      
                      </>
                      ))
                    }
                  </div>
                </div>
              </div>
            </>
            {/* Print component */}
            <div style={{ display: 'none' }}>
              <div ref={componentRef} className="card-body">
                <div className="d-lg-flex">
                  <h6 className="main-content-label mb-4">
                    <span className="d-flex mb-4">
                      <Link to={'/dashboard/orders'}>
                        <img
                          src={require("../../../assets/img/brand/cortenLogo.png")}
                          className="sign-favicon ht-50"
                          alt="logo"
                        />
                      </Link>
                    </span>
                  </h6>
                  <div className="ms-auto">
                    <div className="mb-1">
                      <h5 className="font-weight-bold">Cliente: {client_custom_id || client_id}</h5>
                    </div>
                  </div>   
                </div>

                <div className="row row-sm pt-2">
                  <div className="col col-lg-6">
                    <address className="fontOrangeMetric text-center">{width} {width_length_unit} Ancho </address>
                    <img
                      src={require("../../../assets/img/brand/genericImgN.png")}
                      className="sign-favicon br-5 w-60"
                    />
                  </div>
                  <div className="col col-sm-1 col-lg-1 col-m-1 d-flex align-items-center">
                    <address className="fontOrangeMetric text-center">{length} {width_length_unit} Largo</address>

                  </div>

                  <div className="col col-sm-1 col-lg-1 col-m-1">
                  </div>

                  <div className="col col-lg-4 text-start">
                    <div className="row d-flex justify-content-between">
                      <div className="col d-flex align-items-center">
                        <img
                          src={require("../../../assets/img/brand/greenMachine.png")}
                          className="ht-55"
                        />
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <p className="h1 mb-4 mt-4 text-end">Orden de trabajo</p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="mb-1 text-end">
                        <span className="fs-4">Cliente:</span>
                      </p>
                      <address className="fontOrange text-end">{client_company_name}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Pedido:</span>
                      </p>
                      <address className="fontOrange text-end">{order_custom_id || order_id}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de corte:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tipo de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_type}</address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Espesor:</span>
                      </p>
                      <address className="fontOrange text-end">{thickness} {thickness_unit} </address>
                    </div>
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas de material:</span>
                      </p>
                      <address className="fontOrange text-end">{material_count} Piezas</address>
                    </div>
                   
                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Piezas a cortar:</span>
                      </p>
                      <address className="fontOrange text-end">{cut_count} Piezas</address>
                    </div>

                    <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Notas:</span>
                      </p>
                      <address className="fontOrange text-end">{notes}</address>
                    </div>
                   
                    
                    {payed_quotation?.machine_scheduled_date ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Fecha a maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{formatDateTimeForUserTimeZone(payed_quotation.machine_scheduled_date)}</address>
                    </div> : null}
                    
                    {payed_quotation?.machine_duration_minutes ? <div>
                      <p className="mb-1 text-end">
                        <span className="fs-4">Tiempo en maquina:</span>
                      </p>
                      <address className="fontOrange text-end">{payed_quotation.machine_duration_minutes} Minutos</address>
                    </div> : null}
                    <br />
                  </div>
                </div>
              </div>
            </div>
            {/* Print component */}
            <div className="table-responsive mg-t-40">
              {
                renderQuotationTable()
              }
            </div>
            <div className="card-footer text-end">
              <h3 className="m-4">Acciones: </h3>
              {
                renderActions()
              }
              {
                renderCancelAction()
              }
              <button
                type="button"
                className="btn ripple btn-light mb-1 me-2"
                onClick={handlePrint}
              >
                <i className="fa fa-print"></i> Imprimir Orden
              </button>
              <div className="border m-5" />
              <FilesSection
                user={user}
                order={props.order}
                handleObservations={handleObservations}
                handleUploadImage={handleUploadImage}
                handleDelete={handleDelete}
                reception_image_urls={reception_image_urls}
                delivery_image_urls={delivery_image_urls}
                handleDownload={handleDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OrderDetail;
