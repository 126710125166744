import React, { Fragment } from "react";

import "gantt-task-react/dist/index.css";
import { Gantt, Task, ViewMode } from 'gantt-task-react';


import Header from "../Dashboard/Header";
import { Quotation } from "../../models/quotations";

function GanttChart(props: any) {

    const { isOnlyGanttChart, quotations, handleInputChange, handleDateConfirm, prevPage, nextPage, setRangeToThisDay, setRangeToThisWeek } = props;

    // Colors red #A91B0D, Green #006400, Orange #F28500, Gray #8E918F
    let tasks: Task[] = quotations.map((quotation: Quotation) => {
        let machineColor = '#8E918F';

        let endDate = new Date(quotation.machine_scheduled_date);
        endDate.setMinutes(endDate.getMinutes() + quotation.machine_duration_minutes);

        if (quotation.status === 'payed') {
            machineColor = '#A91B0D';
        }

        if (quotation.status === 'in_progress') {
            machineColor = '#A91B0D';
        }

        if (quotation.status === 'done') {
            machineColor = '#006400';
        }

        let task: Task = {
            start: new Date(quotation.machine_scheduled_date),
            end: endDate,
            name: quotation.quotation_id,
            id: quotation.quotation_id,
            type: 'task',
            progress: 0,
            isDisabled: false,
            styles: {
                backgroundColor: machineColor,
                backgroundSelectedColor: machineColor
            },

        }
        return task;
    });

    return (
        <Fragment>
            {
                isOnlyGanttChart ?
                    <Header title="GANTT CHART" />
                    :
                    null
            }

            <div className="row row-sm">
                <div className="col col-md-12 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="d-block d-sm-flex mt-4">
                                <label
                                    className="form-label mt-3"
                                    htmlFor={"date"}
                                >
                                    Buscar por fecha
                                </label>
                                <input
                                    type={"date"}
                                    name={"exact_date"}
                                    className="form-control mx-1 mt-2"
                                    style={{ maxWidth: "160px" }}
                                    onChange={handleInputChange}
                                    placeholder="Buscar por fecha"
                                />
                                <div className="mx-1 mt-2">
                                    <button className="btn btn-info" onClick={handleDateConfirm}>Buscar corte</button>
                                </div>
                            </div>
                            <div className="d-block d-sm-flex mt-4 ">
                                <span className="ms-sm-auto">
                                    <button
                                        type='button'
                                        className="btn btn-default tablebutton me-2 my-1"
                                        onClick={setRangeToThisWeek}
                                    >
                                        {" Semana "}
                                    </button>
                                    <button
                                        type='button'
                                        className="btn btn-default tablebutton me-2 my-1"
                                        onClick={setRangeToThisDay}
                                    >
                                        {" Dia "}
                                    </button>
                                    <button
                                        type='button'
                                        className="btn btn-defaul tablebutton me-2 d-sm-inline d-block my-1"
                                        onClick={prevPage}
                                    >
                                        {" < "}
                                    </button>
                                    <button
                                        type='button'
                                        className="btn btn-default tablebutton me-2 d-sm-inline d-block my-1"
                                        onClick={nextPage}
                                    >
                                        {" > "}
                                    </button>
                                </span>
                            </div>
                            {
                                tasks.length !== 0 ?
                                    <Gantt listCellWidth="200px"
                                        todayColor='#F8F8F8'
                                        tasks={tasks}
                                        viewMode={ViewMode.Hour} />
                                    :
                                    <div>No hay cortes agendados</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GanttChart;
